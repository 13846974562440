
function initForm() {
    var $rsvp_form = $('#rsvp-form'),
        $optional   = $rsvp_form.find('fieldset.guest.optional');

    $rsvp_form.on('click', '.add-guest', function() {
        var template = $(this).data('template'),
            suffix   = $('fieldset.guest.new').length+1;
        template = template.replace(/blockid/g, "new"+suffix);
        $block = $(template);
        $('fieldset.guest').last().after($block);
        $block.find('input[type=text]').first().focus();
        return false;
    });
    $rsvp_form.on('click', '.remove-guest', function() {
        $(this).parent('fieldset').remove();
        return false;
    });
    $rsvp_form.on('mouseenter', '.remove-guest', function() {
        $(this).parent('fieldset').addClass('highlight');
        return false;
    });
    $rsvp_form.on('mouseleave', '.remove-guest', function() {
        $(this).parent('fieldset').removeClass('highlight');
        return false;
    });
    $rsvp_form.on('click', 'input[class=rsvp]', function() {
        var $this       = $(this),
            value       = $this.val();

        switch (value) {
          case 'no':
            $rsvp_form.removeClass('rsvp-yes rsvp-maybe rsvp-none');
            break;
          case 'yes':
          case 'maybe':
            $rsvp_form.removeClass('rsvp-no rsvp-none');
        }
        $rsvp_form.addClass('rsvp-'+value);
        $optional.not('.option-'+value).prop("disabled", true);
        $optional.filter('.option-'+value).prop("disabled", false);

    });
}
function main() {
    initForm();
}



// Init Instantlinks
// InstantClick.on('change', main);
// InstantClick.init();

$(main);